import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCatalogos() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_admin ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.menu_ciclos ?(
            <Button href="/Ciclos" className="botonesMenu" color="success">
              <i class="far fa-calendar-alt fa-7x"></i>
              <br />
              <br />
              Ciclos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="far fa-calendar-alt fa-7x"></i>
          <br />
          <br />
          Ciclos
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_periodos_admin ?(
            <Button href="/PeriodosAdmin" className="botonesMenu" color="success">
              <i class="far fa-calendar-check fa-7x"></i>
              <br />
              <br />
              Periodos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="far fa-calendar-check fa-7x"></i>
          <br />
          <br />
          Periodos
        </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_departamentos ?(
            <Button href="/Departamentos" className="botonesMenu" color="success">
            <i class="fas fa-clipboard-list fa-7x"></i>
              <br />
              <br />
              Departamentos
            </Button>
            ):<Button disabled className="botonesMenu" color="success">
            <i class="fas fa-clipboard-list fa-7x"></i>
              <br />
              <br />
              Departamentos
            </Button> }
          </Col>
        </Row>
        <br />
        <Row>
        
          {/* <Col md={4} align="center">
            {user.menu_proveedores ?(
            <Button href="/Proveedores" className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button> }
          </Col> */}
          {/* <Col md={4} align="center">
          {user.menu_presupuestos ?(
            <Button href="/Presupuestos" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Presupuestos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Presupuestos
        </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_gastos ?(
            <Button href="/Gastos" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Gastos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Gastos
            </Button> }
            </Col> */}
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCatalogos;
