import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesTiendas() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      {user.menuTiendas ? (
        <div className="container">
          <br />
          <br />
          <h3>VENTAS</h3>
          <Row>
            <Col md={4} align="center">
              {user.ventasTiendas ? (
                <Button
                  href="/VentasTiendasCreate"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-receipt fa-7x"></i>
                  <br />
                  <br />
                  Venta
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-receipt fa-7x"></i>
                  <br />
                  <br />
                  Venta
                </Button>
              )}
            </Col>

            <Col md={4} align="center">
              {user.ventasTiendas ? (
                <Button
                  href="/ApartadosTiendasCreate"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-receipt fa-7x"></i>
                  <br />
                  <br />
                  Apartados
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-receipt fa-7x"></i>
                  <br />
                  <br />
                  Apartados
                </Button>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} align="center">
              {user.listadoVentasTiendas ? (
                <Button
                  href="/ListadoVentasTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Reporte de Ventas
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Reporte de Ventas
                </Button>
              )}
            </Col>

            <Col md={4} align="center">
              {user.listadoVentasTiendas ? (
                <Button
                  href="/ListadoApartadosTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Reporte de Apartados
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Reporte de Apartados
                </Button>
              )}
            </Col>

            <Col md={4} align="center">
              {user.corteTiendas ? (
                <Button
                  href="/CorteTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-money-check-alt fa-7x"></i>
                  <br />
                  <br />
                  Corte Diario
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-money-check-alt fa-7x"></i>
                  <br />
                  <br />
                  Corte Diario
                </Button>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} align="center">
              {user.entregarNota ? (
                <Button
                  href="/EntregarNota"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-truck fa-7x"></i>
                  <br />
                  <br />
                  Entregar Nota
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-truck fa-7x"></i>
                  <br />
                  <br />
                  Entregar Nota
                </Button>
              )}
            </Col>
         
            <Col md={4} align="center">
              {user.devolucionesTiendas ? (
                <Button
                  href="/ListadoDevolucionesTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-undo fa-7x"></i>
                  <br />
                  <br />
                  Devoluciones
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-undo fa-7x"></i>
                  <br />
                  <br />
                  Devoluciones
                </Button>
              )}
            </Col>

            <Col md={4} align="center">
              {user.devolucionesTiendas ? (
                <Button
                  href="/ListadoDevolucionesTiendasAdmin"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-undo fa-7x"></i>
                  <br />
                  <br />
                  Aplicar Devoluciones
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-undo fa-7x"></i>
                  <br />
                  <br />
                  Aplicar Devoluciones
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          <br />
          <h3>ADMIN</h3>
          <Row>
            <Col md={4} align="center">
              {user.abonosTiendas ? (
                <Button
                  href="/ListadoAbonosTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-donate fa-7x"></i>
                  <br />
                  <br />
                  Abonos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-donate fa-7x"></i>
                  <br />
                  <br />
                  Abonos
                </Button>
              )}
            </Col>

            <Col md={4} align="center">
              {user.clientes_create ? (
                <Button
                  href="/ListadoClientesTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Clientes
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Clientes
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.edoCtaClientes ? (
                <Button
                  href="/EstadoCuentaTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Estado Cuenta
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Estado Cuenta
                </Button>
              )}
            </Col>
          </Row>
          <br />
          <Row>
           

            <Col md={4} align="center">
              {user.gastosTiendas ? (
                <Button
                  href="/GastosTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-clipboard-list fa-7x"></i>
                  <br />
                  <br />
                  Gastos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-clipboard-list fa-7x"></i>
                  <br />
                  <br />
                  Gastos
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.gastosTiendas ? (
                <Button
                  href="/DinerosTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-dollar-sign fa-7x"></i>
                  <br />
                  <br />
                  Entradas Dinero
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-dollar-sign fa-7x"></i>
                  <br />
                  <br />
                  Entradas Dinero
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.ventasTiendas ? (
                <Button
                  href="/ReporteUltimoPrecio"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-dollar-sign fa-7x"></i>
                  <br />
                  <br />
                  Ultimo Precio
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-dollar-sign fa-7x"></i>
                  <br />
                  <br />
                  Ultimo Precio
                </Button>
              )}
            </Col>
          </Row>

          <hr />
          <br />
          <h3>INVENTARIOS</h3>
          <Row>
            <Col md={4} align="center">
              {user.inventariosTiendas ? (
                <Button
                  href="/ListadoInventariosTiendas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-warehouse fa-7x"></i>
                  <br />
                  <br />
                  Inventario
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-warehouse fa-7x"></i>
                  <br />
                  <br />
                  Inventario
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.menu_traspasos_salida ? (
                <Button
                  href="/ListadoTraspasosSalida"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-sign-out-alt fa-6x"></i>
                  <br />
                  <br />
                  Traspasos Salida
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-sign-out-alt fa-6x"></i>
                  <br />
                  <br />
                  Traspasos Salida
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.menu_traspasos_entrada ? (
                <Button
                  href="/ListadoTraspasosEntrada"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-sign-in-alt fa-6x"></i>
                  <br />
                  <br />
                  Traspasos Entrada
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-sign-in-alt fa-6x"></i>
                  <br />
                  <br />
                  Traspasos Entrada
                </Button>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} align="center">
              {user.disponible_vendedores ? (
                <Button
                  href="/ListadoDisponibleVendedor"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-list-alt fa-7x"></i>
                  <br />
                  <br />
                  Disponible
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-list-alt fa-7x"></i>
                  <br />
                  <br />
                  Disponible
                </Button>
              )}
            </Col>

            <Col md={4} align="center">
              {user.ajustesInventario ? (
                <Button
                  href="/ListadoAjustesInventario"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-plus fa-7x"></i>
                  <br />
                  <br />
                  Ajuste Inventario
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-plus fa-7x"></i>
                  <br />
                  <br />
                  Ajuste Inventario
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.seguimiento_inventarios ? (
                <Button
                  href="/ListadoKardex"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-clipboard-list fa-7x"></i>
                  <br />
                  <br />
                  Kardex
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-clipboard-list fa-7x"></i>
                  <br />
                  <br />
                  Kardex
                </Button>
              )}
            </Col>
          </Row>
        </div>
      ) : undefined}
    </>
  );
}

export default BotonesTiendas;
