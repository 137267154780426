import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

function ListadoSurtidos() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-90);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [surtidos, setSurtidos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(()=>{
    axios
    .get(`${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              pedidos: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              clientes: a.pedidos[0].clientes[0].nombre_comercial,
              codigoClientes: a.pedidos[0].clientes[0].codigo,
              idClientes: a.pedidos[0].clientes[0]._id,
              observaciones: a.observaciones,
              numero: a.idSurtido,
              comisionTDC: a.pedidos[0].comisionTDC,
              comisionFlete: a.pedidos[0].comisionFlete,
              impuestos: a.pedidos[0].impuestos,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              calle: a.pedidos[0].clientes[0].calle,
              numero_ext: a.pedidos[0].clientes[0].numero_ext,
              numero_int: a.pedidos[0].clientes[0].numero_int,
              pais: a.pedidos[0].clientes[0].pais,
              estado: a.pedidos[0].clientes[0].estado,
              ciudad: a.pedidos[0].clientes[0].ciudad,
              delegacion: a.pedidos[0].clientes[0].delegacion,
              colonia: a.pedidos[0].clientes[0].colonia,
              cp: a.pedidos[0].clientes[0].cp,
              paqueteria: a.pedidos[0].paqueterias[0].name,
              idPaqueteria: a.pedidos[0].paqueterias[0]._id,
              RFC: a.pedidos[0].clientes[0].RFC,
              area: a.pedidos[0].areas[0].name,
              vendedor:
                a.pedidos[0].colaboradores[0].nombre +
                " " +
                a.pedidos[0].colaboradores[0].apellido,
              is_active: a.is_active,
              telefono: a.pedidos[0].clientes[0].telefono,
              creado: a.pedidos[0].creado[0].nombre + " " + a.pedidos[0].creado[0].apellido,
              fechaHora: moment(a.createdAt).utcOffset('-0600').format("DD-MM-YYYY") + " " + moment(a.createdAt).utcOffset('-0600').format("HH:mm:ss"),
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setSurtidos(allSurtidos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio ,selectedFechaFin])

  const ListaSurtidos = comments.sort((a, b) =>
    a.idSurtido > b.idSurtido ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Pedido", "Surtio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    doc.save(`Surtidos.pdf`);
  }

  function excel() {
    const dataExcel = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Pedido: a.pedidos,
          Surtio: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: ["Numero", "Fecha", "Cliente", "Pedido", "Surtio"],
        sheetHeader: ["Numero", "Fecha", "Cliente", "Pedido", "Surtio"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Pedido", "Surtio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Notas",
          email: mailTo,
          fileName: "ListadoNotas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    vendedor,
    telefono,
    creado,
    fechaHora
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos: a.articulos[0].codigo,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            cajas: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cajas += item.cajas;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);

        let TC = agrupadoValues.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;

        const data = agrupadoValues
        .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
        .map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 20, 10);

        let alto = 5
        
        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 60, 15);
        doc.text(`Nota:`, 35, 20);
        doc.text(`${numero}`, 60, 20);
        doc.text(`Pedido:`, 35, 25);
        doc.text(`${pedidos}`, 60, 25);

        doc.text(`Ejecutivo:`, 120, 15);
        doc.text(`${vendedor}`, 150, 15);
        doc.text(`Almacen:`, 120, 20);
        doc.text(`${area}`, 150, 20);

        doc.setFontSize(8);
        doc.text(`Creado por: ${creado}`, 120, 32);
        doc.text(`Fecha creado: ${fechaHora}`, 120, 37);

        doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 10, 32);
        doc.text(`RFC: ${RFC}`, 10, 37);
        doc.text(`Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int}`, 10, 47);
        doc.text(`Edo: ${estado}, Ciudad: ${ciudad}, CP: ${cp}`, 10, alto + 57);
        doc.text(`Del: ${delegacion}, Col: ${colonia}`, 10, alto + 62);

        doc.setFontSize(12);
        doc.text(`Paqueteria: ${paqueteria}`, 120, 50);
        doc.text(`Almacen: ${area}`, 120, 57);

        doc.autoTable({
          head: [["Articulo", "Cajas", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 12,
            // overflow: "linebreak",
            // columnWidth: "wrap",
            // // font: 'arial',
            // overflowColumns: "linebreak",
          },
          margin: { left: 7, right: 7 },
          startY: alto + 70,
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              new Intl.NumberFormat("en-US").format(TM) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(subTotal.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              `Comision`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(iva.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(comisionTDC.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(comisionFlete.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(total_general.toFixed(2)),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.save(`Nota-${numero}.pdf`);
        //   }
        // });
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function excelFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos: a.articulos[0].codigo,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          return groups;
        }, []);
        let agrupadoValues = Object.values(agrupado);
        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        let totalParcial = 0;
        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return {
            Articulos: a.articulos,
            Cantidad: a.cantidad,
            Precio: a.precio,
            TotalParcial: totalParcial,
          };
        });
        const dataExportExcel = Object.values(data);
        const dataExcelLimpia = dataExportExcel.filter(function (el) {
          return el != null;
        });

        const data2 = [
          {
            Cliente: codigoClientes + " " + clientes,
            Nota: numero,
            RFC: RFC,
            Direccion:
              calle +
              " " +
              numero_int +
              " " +
              numero_ext +
              " " +
              estado +
              " " +
              ciudad +
              " " +
              delegacion +
              " " +
              colonia +
              " " +
              cp,
            Impuestos: impuestos,
            IVA: iva,
            TotalGeneral: total_general,
            ComisionTDC: comisionTDC,
            ComisionFlete: comisionFlete,
            Fecha: fecha,
            Observaciones: observaciones,
          },
        ];

        var option = {};
        option.fileName = `Nota ${numero}`;
        option.datas = [
          {
            sheetData: data2,
            sheetName: `Datos Generales`,
            sheetFilter: [
              "Nota",
              "Fecha",
              "Cliente",
              "RFC",
              "Direccion",
              "Impuestos",
              "IVA",
              "TotalGeneral",
              "ComisionTDC",
              "ComisionFlete",
              "Observaciones",
            ],
            sheetHeader: [
              "Nota",
              "Fecha",
              "Cliente",
              "RFC",
              "Direccion",
              "Impuestos",
              "IVA",
              "TotalGeneral",
              "ComisionTDC",
              "ComisionFlete",
              "Observaciones",
            ],
          },
          {
            sheetData: dataExcelLimpia,
            sheetName: `Articulos`,
            sheetFilter: ["Articulos", "Cantidad", "Precio", "TotalParcial"],
            sheetHeader: ["Articulos", "Cantidad", "Precio", "TotalParcial"],
          },
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Pedido", field: "pedidos", sortable: true },
    { name: "Surtió", field: "user", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_surtidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Notas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Notas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                if (a.is_active == "No"){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.pedidos}</td>
                      <td>{a.user}</td>
                        <td>CANCELADA</td>
                    </tr>
                  );
                }else{
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.pedidos}</td>
                      <td>{a.user}</td>
                        <>
                          <td>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes,
                                  a.vendedor,
                                  a.telefono,
                                  a.creado,
                                  a.fechaHora
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="primary"
                              onClick={(e) =>
                                excelFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes
                                )
                              }
                            >
                              <i class="far fa-file-excel"></i>
                            </Button>
                          </td>
                        </>
                    </tr>
                  );
                }

              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoSurtidos;
