import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesTraspasos() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_inventarios ?(
      <div className="container">
        <br />
        <br />
        <Row>
          

        {/*<Col md={4} align="center">
          {user.menu_traspasos_salida ?(
            <Button href="/ListadoPendienteTraspasar" className="botonesMenu" color="success">
              <i class="fas fa-truck-moving fa-7x"></i>
              <br />
              <br />
              Pend Traspasar
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-truck-moving fa-7x"></i>
          <br />
          <br />
          Pend Traspasar
        </Button> }
          </Col> */}

          

        <Col md={4} align="center">
          {user.menu_traspasos_salida ?(
            <Button href="/ListadoTraspasosSalida" className="botonesMenu" color="success">
              <i class="fas fa-sign-out-alt fa-6x"></i>
              <br />
              <br />
              Traspasos Salida
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-out-alt fa-6x"></i>
          <br />
          <br />
          Traspasos Salida
        </Button> }
          </Col>
          

          
          <Col md={4} align="center">
          {user.menu_traspasos_entrada ?(
            <Button href="/ListadoTraspasosEntrada" className="botonesMenu" color="success">
              <i class="fas fa-sign-in-alt fa-6x"></i>
              <br />
              <br />
              Traspasos Entrada
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-in-alt fa-6x"></i>
          <br />
          <br />
          Traspasos Entrada
        </Button> }
          </Col>

          
          </Row>
        <br />
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesTraspasos;
