import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function TraspasosSalidaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TRASPASOS_SALIDA = process.env.REACT_APP_URL_TRASPASOS_SALIDA;
  const URL_ARTICULOS_TS = process.env.REACT_APP_URL_ARTICULOS_TS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_BODEGA_CENTRO = process.env.REACT_APP_AREAS_BODEGA_CENTRO;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_PROPORCION = process.env.REACT_APP_URL_ARTICULOS_PROPORCION;


  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [inventarios, setInventarios] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [areaSalida, setAreaSalida] = useState("");
  const [areaDestino, setAreaDestino] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [articulosUnicos, setArticulosUnicos] = useState([]);


  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      articulosNombre: "",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      costoUnitario: 0,
      colores:[],
      tallas:[],
      colorNombre: "",
      tallaNombre: "",
      idColor: "",
      idTalla: "",
    },
  ]);

  const [inputArticulos, setInputArticulos] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cajas: 0,
      articulosNombre: "",
      colores:[],
      tallas:[],
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
   
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveTraspasosSalida = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;
    try {
      await axios
        .post(
          URL_TRASPASOS_SALIDA,
          {
            fecha,
            areaSalida,
            areaDestino,
            observaciones,
            user: user.id,
            total,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad > 0) {
            axios
              .post(
                URL_ARTICULOS_TS,
                {
                  traspasosSalida: data.data._id,
                  articulos: a.articulos,
                  cantidad: a.cantidad,
                  inventarios: a.idInv,
                  areaDestino,
                  costoUnitario: a.costoUnitario,
                  colores: a.idColor,
                  tallas: a.idTalla,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Traspaso Salida",
                        detalle: `${areaSalida} ${total}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
                console.log(error);
                setValidaBoton(true);
              });
            }else{
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Traspaso Salida",
                      detalle: `${areaSalida} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        costoUnitario: 0,
        colores:[],
        tallas:[],
        colorNombre: "",
        tallaNombre: "",
        idColor: "",
        idTalla: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(AREAS_BODEGA_CENTRO == areaSalida){
          if(i.cantInv >= event.target.value){
            i[event.target.name] = event.target.value;
          }else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "La cantidad excede tu inventario o Disponible!",
              showConfirmButton: false,
            });
            i.cantidad = 0;
          }
        } else if (i.cantInv >= event.target.value) {
          i[event.target.name] = event.target.value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario o Disponible!",
            showConfirmButton: false,
          });
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };



  function BuscaArticulo(id, selectedArticulo) {

      inventarios.map((a) => {
        if (a._id == selectedArticulo._id) {
          let idArticulo = a.idArticulo;
          let cantInv = parseFloat(a.cantidad)
          let idInv = a._id
          let costoUnitario = a.costoUnitario;
          let articulosNombre = a.codigo
          let colores = a.colores;
          let tallas = a.tallas;
          handleChangeInputArticuloUnico(id, idArticulo, cantInv, idInv, costoUnitario, articulosNombre, colores, tallas);
        }
      });

  }
  const handleChangeInputArticuloUnico = (
    id,
    idArticulo,
    cantInv,
    idInv,
    costoUnitario,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.costoUnitario = parseFloat(costoUnitario)
        i.articulosNombre = articulosNombre;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };




  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  }

  function jalaAlmacenSalida(e) {
    setAreaSalida(e);
    toggleProgreso()

      axios
        .get(`${URL_INVENTARIOS}/traspasos/${e}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allInventarios = res.data;
          setInventarios(allInventarios);

          let unicos = allInventarios.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.idArticulo === value.idArticulo)
          );
          setArticulosUnicos(unicos);
        })
        .catch((err) => {
          console.log(err);
        });

       
    
        setTimeout(()=>{
          setModalProgreso(false)
        }, 3000)

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        costoUnitario: 0,
        colores:[],
        tallas:[],
        colorNombre: "",
        tallaNombre: "",
        idColor: "",
        idTalla: "",
      },
    ]);
  }

console.log("hola",inputFields);

  function jalaAlmacenDestino(e) {
    setAreaDestino(e);
    toggleProgreso()

    axios
    .get(`${URL_INVENTARIOS}/traspasos/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);

        let unicos = allInventarios.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArticulo === value.idArticulo)
        );
        setArticulosUnicos(unicos);
      })
      .catch((err) => {
        console.log(err);
      });

      setTimeout(()=>{
        setModalProgreso(false)
      }, 3000)

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        colores:[],
        tallas:[],
        colorNombre: "",
        tallaNombre: "",
        idColor: "",
        idTalla: "",
      },
    ]);
  }


  const handleChangeInputProporcion = (id, event) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  function BuscaArticuloProporcion(id, selectedArticulo) {
    inventarios.map((a) => {
      if (a.idArticulo == selectedArticulo.idArticulo) {
        let idArticulo = selectedArticulo.idArticulo;
        let articulosNombre = selectedArticulo.codigo + " " + selectedArticulo.nombre;
        let colores = a.colores;
        let tallas = a.tallas;
        handleChangeInputArticuloProporcion(
          id,
          idArticulo,
          articulosNombre,
          colores,
          tallas
        );
      }
    });
  }

  const handleChangeInputArticuloProporcion = (
    id,
    idArticulo,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.articulosNombre = articulosNombre;
        i.cajas=0
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };


  function agregarProporcion(idArticulo, articulosNombre,  cajas, colores, tallas) {
    if(cajas == 0){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La cantidad debe ser mayor a 0",
      })
      return
    }
    axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        const newInputFields = allArticulos.map((i) => {
          // Encuentra el inventario correspondiente
          const inventario = inventarios.find(
            (inv) =>
              inv.articulo === i.articulo &&
              inv.color === i.colores[0].name &&
              inv.talla === i.tallas[0].name
          );
        
          // Calcula la cantidad final basada en la disponibilidad del inventario
          const cantidadFinal = inventario
            ? Math.min(inventario.cantidad, i.cantidad * cajas)
            : i.cantidad * cajas;
        
          if (inventario) {
            return {
              id: uuidv4(),
              articulos: idArticulo,
              idInv: inventario._id,
              cantInv: inventario.cantidad,
              articulosNombre: articulosNombre,

              cantidad: cantidadFinal,
              costoUnitario: inventario.costoUnitario,

              idTalla: i.tallas[0]._id,
              tallaNombre: i.tallas[0].name,
              idColor: i.colores[0]._id,
              colorNombre: i.colores[0].name,
              colores: colores,
              tallas: tallas,
            };
          }
          return null;
        }).filter(i => i !== null);
        
        setInputFields((prevInputFields) => {
          const updatedInputFields = [...prevInputFields, ...newInputFields];
        
          const tallaOrder = ["EECH", "ECH", "CH", "M", "G", "EG", "2EG", "3EG", "4EG", "5EG", "6EG", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "5/6", "7/8", "10/12", "14/16"];
        
          const sortedInputFields = updatedInputFields.sort((a, b) => {
            if (a.articulosNombre < b.articulosNombre) return -1;
            if (a.articulosNombre > b.articulosNombre) return 1;
        
            if (a.colorNombre < b.colorNombre) return -1;
            if (a.colorNombre > b.colorNombre) return 1;
        
            return tallaOrder.indexOf(a.tallaNombre) - tallaOrder.indexOf(b.tallaNombre);
          });
        
          // Calculate total quantities and amounts
          const totalCantidad = sortedInputFields.reduce((acc, curr) => acc + parseFloat(curr.cantidad), 0);

          setTotal(totalCantidad);
  
          return sortedInputFields;
        });
        
        setInputArticulos([ {
          id: uuidv4(),
          articulos: "",
          cajas: 0,
          articulosNombre: "",
          colores:[],
          tallas:[],
        }])

      })
      .catch((err) => {
        console.log(err);
      });
  }

  
  const options = articulosUnicos.map((option) => {
    const junta = option.codigo + " " + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });


  return (
    <>
      <Header />
      <br />
      <br />
      {user.traspasos_salida_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Traspaso Salida</h3>
          <Form onSubmit={saveTraspasosSalida}>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen Salida</Label>
                {user.areas == AREAS_GENERAL ? (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={areaSalida}
                    required
                    onChange={(e) => {
                      jalaAlmacenSalida(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Salida</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (a._id != AREAS_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                ) : (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={areaSalida}
                    required
                    onChange={(e) => {
                      jalaAlmacenSalida(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Salida</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                )}
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen Destino</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={areaDestino}
                  required
                  onChange={(e) => {
                    setAreaDestino(e.target.value);
                  }}
                >
                  <option value="0">Almacen Destino</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a._id != AREAS_GENERAL) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>

            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <h4 id="logoutBoton">TOTALES {total} pzas.</h4>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Selecciona</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
            </Row>

            {inputArticulos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Autocomplete
                      size="small"
                      value={inputField.articulosNombre}
                      onChange={(event, selectedArticulo) => {
                        BuscaArticuloProporcion(
                          inputField.id,
                          selectedArticulo
                        );
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      name="articulosNombre"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cajas"
                      value={inputField.cajas}
                      required
                      onChange={(event) =>
                        handleChangeInputProporcion(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={() => agregarProporcion(inputField.articulos, inputField.articulosNombre, inputField.cajas, inputField.colores, inputField.tallas)}
                      tabindex="-1"
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}

            <br />
            <hr />

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Selecciona</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Modelo</Label>
              </Col>  
              <Col md={1}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Inventario</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                
                  <Col md={3}>
                   <Autocomplete
                size="small"
                value={inputField.articulosNombre}
                onChange={(event, selectedArticulo) => {
                  BuscaArticulo(inputField.id, selectedArticulo);
                }}
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.junta}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona"
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.codigo} {option.nombre}
                  </React.Fragment>
                )}
              />
                  </Col>

                  <Col md={2}>
               <Input
                      
                      name="articulosNombre"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                  <Input
                      name="idColor"
                      type="select"
                      value={inputField.idColor}
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    >
                    <option value="">Selecciona</option>
                    {inputField.colores?.map((a)=>{
                        return <option value={a._id}>{a.name}</option>
                      })}
                      </Input>
                  </Col>
                  <Col md={1}>
                  <Input
                      name="idTalla"
                      type="select"
                      value={inputField.idTalla}
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    >
                    <option value="">Selecciona</option>
                    {inputField.tallas?.map((a)=>{
                        return <option value={a._id}>{a.name}</option>
                      })}
                      </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="cantInv"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantInv}
                      required
                      disabled
                    />
                  </Col>

                


                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">TOTALES {total} pzas.</h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoTraspasosSalida"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosSalidaCreate;
