import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import Baja from "../../Baja"

function ListadoGastosGeneral() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS_GENERAL = process.env.REACT_APP_URL_GASTOS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [gastos, setGastos] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState();
  const [subtotal, setSubtotal] = useState();
  const [iva, setIva] = useState();
  const [descripcion, setDescripcion] = useState("");
  const [totalEdit, setTotalEdit] = useState();
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
      axios
        .get(
          `${URL_GASTOS_GENERAL}GastosDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allGastos = response.data;
          let arrayTabla = allGastos
            .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                is_active: a.is_active,
                fecha: a.fecha,
                descripcion: a.descripcion,
                subtotal: a.subtotal,
                iva: a.iva,
                total: a.total,
                concepto: a.conceptosGastos[0].name,
                idConcepto: a.conceptosGastos[0]._id,
                area: a.areas[0].name,
                idAreas: a.areas[0]._id,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let dataFinal = Object.values(arrayTabla);
          setComments(dataFinal);
          setGastos(dataFinal);
        })
        .catch((err) => {
          console.log(err);
        });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  useEffect(() => {
    axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });

    }, []);

  let total = 0;
  let totalTabla = 0;

  function PDFTabla() {
    total = 0;
    const data = gastos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total);
        return [c.fecha, c.area, c.concepto, c.descripcion, importePDF];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 38, 18);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Concepto", "Descripcion", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleGastos.pdf");
  }

  function excel() {
    const dataExcel = gastos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        return {
          Fecha: c.fecha,
          Concepto: c.concepto,
          Descripcion: c.descripcion,
          Importe: c.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `DetalleGastos-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `DetalleGastos`,
        sheetFilter: ["Fecha", "Concepto", "Descripcion", "Importe"],
        sheetHeader: ["Fecha", "Concepto", "Descripcion", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    total = 0;
    const data = gastos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total);
        return [c.fecha, c.area, c.concepto, c.descripcion, importePDF];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 38, 18);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Concepto", "Descripcion", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Lista de Gastos",
          email: mailTo,
          fileName: "ListaGastos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Gastos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const editGasto = (event) => {
    const URL_GASTOS_GENERAL_EDIT = `${process.env.REACT_APP_URL_GASTOS_GENERAL}/${idEdit}`;
    event.preventDefault();
    axios
      .all([
        axios.patch(
          URL_GASTOS_GENERAL_EDIT,
          {
            fecha,
            subtotal,
            iva,
            total: totalEdit,
            descripcion,
            areas: AREAS_GENERAL,
            conceptosGastos:selectedConcepto,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Edit Gasto",
            detalle: `Fecha: ${fecha} / Descripcion: ${descripcion} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Concepto", field: "concepto", sortable: true },
    { name: "Descripcion", field: "descripcion", sortable: true },
    { name: "Importe", field: "total", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.concepto.toLowerCase().includes(search.toLowerCase()) ||
          comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idAreas.includes(selectedArea)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedArea,
  ]);

  const calcula = (e) => {
    setSubtotal(e);
    setTotalEdit(parseFloat(e) + parseFloat(iva));
  };

  const calculaIVA = (e) => {
    setIva(e);
    setTotalEdit(parseFloat(subtotal) + parseFloat(e));
  };

  function jalaInfo(id, subtotal, iva, total, concepto, descripcion, fecha){
    setIdEdit(id)
    setSubtotal(subtotal)
    setIva(iva)
    setTotalEdit(total)
    setSelectedConcepto(concepto)
    setDescripcion(descripcion)
    setFecha(fecha)
    toggleEdit()
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    {user.menu_gastos ? (
                      <Button
                        size="sm"
                        href="/GastosCreate"
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Gasto
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        disabled
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Gasto
                      </Button>
                    )}
                    <Button
                      size="sm"
                      href="/MenuGastos"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Lista de Gastos</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Gastos</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      if(c.is_active == "Si"){
                      totalTabla = totalTabla + c.total;
                      return (
                        <tr>
                          <td>{c.fecha}</td>
                          <td>{c.concepto}</td>
                          <td>{c.descripcion}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total)}
                          </td>
                          <td>
                          <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => jalaInfo(c._id, c.subtotal, c.iva, c.total, c.idConcepto, c.descripcion, c.fecha)}
                            >
                              <i class="far fa-edit"></i>{" "}
                            </Button>
                            <Baja
                              idStatus={c._id}
                              is_active={c.is_active}
                              URL_BAJA={URL_GASTOS_GENERAL}
                            />
                          </td>
                        </tr>
                      )}else{
                        return (
                          <tr style={{backgroundColor:"#ed8c8c"}}>
                            <td>{c.fecha}</td>
                            <td>{c.concepto}</td>
                            <td>{c.descripcion}</td>
                            <td>CANCELADO</td>
                            <td>
                            <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => jalaInfo(c._id, c.subtotal, c.iva, c.total, c.idConcepto, c.descripcion, c.fecha)}
                              >
                                <i class="far fa-edit"></i>{" "}
                              </Button>
                              <Baja
                                idStatus={c._id}
                                is_active={c.is_active}
                                URL_BAJA={URL_GASTOS_GENERAL}
                              />
                            </td>
                          </tr>
                        )
                      }
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTabla)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
         
          <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4> Editar Gasto</h4>
            </ModalHeader>
            <ModalBody>
            <Row>

            <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Fecha
                </Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              </Row>
              <Row>
            <Col md={6}>
                <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    // bsSize="sm"
                    type="select"
                    required
                    value={selectedConcepto}
                    onChange={(e) => setSelectedConcepto(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {conceptosGastos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                  </Col>


              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Descripcion
                </Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Subtotal
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Subtotal"
                  value={subtotal}
                  required
                  onChange={(e) => calcula(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  IVA
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="IVA"
                  value={iva}
                  required
                  onChange={(e) => calculaIVA(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Total
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder={total}
                  disabled
                />
              </Col>
            </Row>

            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={editGasto}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoGastosGeneral;
