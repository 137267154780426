import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { makeStyles } from "@material-ui/core/styles";
import qrcode from "qrcode-js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ApartadosTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const URL_ARTICULOS_VENTAS_TIENDAS =
    process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS;
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_BODEGA_CENTRO = process.env.REACT_APP_AREAS_BODEGA_CENTRO;
  const AREAS_NOYDI52 = process.env.REACT_APP_AREAS_NOYDI52;
  const AREAS_ZOE365 = process.env.REACT_APP_AREAS_ZOE365;
  const AREAS_NOYDI8 = process.env.REACT_APP_AREAS_NOYDI8;
  const AREAS_PALOMARES409 = process.env.REACT_APP_AREAS_PALOMARES409;
  const AREAS_BICENTENARIO89 = process.env.REACT_APP_AREAS_BICENTENARIO89;
  const AREAS_BICENTENARIO24 = process.env.REACT_APP_AREAS_BICENTENARIO24;
  const AREAS_BICENTENARIO52 = process.env.REACT_APP_AREAS_BICENTENARIO52;
  const AREAS_PARIAN59 = process.env.REACT_APP_AREAS_PARIAN59;
  // const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_DEPARTAMENTOS_CLIENTES =
    process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;
  const AREAS_BODEGA_SUR = process.env.REACT_APP_AREAS_BODEGA_SUR;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [cliente, setCliente] = useState("");
  const [paqueterias, setPaqueterias] = useState([]);
  const [paqueteria, setPaqueteria] = useState("");
  const [idPaqueteria, setIdPaqueteria] = useState("");
  const [inventarios, setInventarios] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [fecha, setFecha] = useState(hoy);
  const [condPago, setCondPago] = useState("Credito");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [destino, setDestino] = useState("NA");
  const [terminal, setTerminal] = useState("NA");
  const [cortesia, setCortesia] = useState(0);
  const [fiscal, setFiscal] = useState("No");
  const [saldo, setSaldo] = useState(0);

  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInv: "",
      cantInv: 0,
      articulos: "",
      articulosNombre: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      precioOriginal: 0,
      total: 0,
      linea: "",
      familia: "",
      marca: "",
      precioEsp: "Si",
      venta: 0,
      costoUnitario: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  // Crear Clientes
  const [vistaCliente, setVistaCliente] = useState(false);
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("NA");
  const [RFC, setRFC] = useState("NA");
  const [calle, setCalle] = useState("NA");
  const [numero_ext, setNumeroExt] = useState("NA");
  const [numero_int, setNumeroInt] = useState("NA");
  const [delegacion, setDelegacion] = useState("NA");
  const [estado, setEstado] = useState("NA");
  const [pais, setPais] = useState("Mexico");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("NA");
  const [ciudad, setCiudad] = useState("NA");
  const [plazo, setPlazo] = useState(0);
  const [regFiscal, setRegFiscal] = useState("NA");
  const [telefono, setTelefono] = useState("1");
  const [email, setEmail] = useState("a@a.a");
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [selectedPaqueteria, setSelectedPaqueteria] = useState("");
  const [selectedAreaCliente, setSelectedAreaCliente] = useState("");
  const [selectedVendedorInput, setSelectedVendedorInput] = useState("");
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [observacionesCliente, setObservacionesCliente] = useState("NA");
  const [forma_pago, setFormaPago] = useState("Contado");
  const [descuentoCliente, setDescuentoCliente] = useState(0);

  useMemo(() => {
    if (selectedArea) {
      axios
        .get(`${URL_INVENTARIOS}AcumuladoAlmacen/${selectedArea}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allInventarios = res.data;
          setInventarios(allInventarios);
          setSelectedAreaName(res.data[0].area);
          let result = allInventarios.map((a) => a.idArticulo);
          setIdsArticulos(result);

          let unicos = allInventarios.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.idArticulo === value.idArticulo)
          );
          setArticulosUnicos(unicos);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    axios
      .get(`${URL_CLIENTES}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedArea, selectedColaborador]);

  useEffect(() => {
    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PAQUETERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPaqueterias = res.data;
        setPaqueterias(allPaqueterias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrupos = res.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveVenta = async (event) => {
    event.preventDefault();
    let cantidadIF = inputFields.length;
    let contador = 0;

    if (cambiarPrecio == false && user.precio_menor == false) {
      inputFields.map(async (a) => {
        if (a.precioOriginal - 51 < a.precio) {
          return (contador = contador + 1);
        }
      });
    } else {
      contador = inputFields.length;
    }

    if (contador == cantidadIF) {
      let efeGuarda = parseFloat(efectivo) + parseFloat(saldo);

      let prefijo;
      if (fiscal == "Si") {
        if (user.areas == AREAS_NOYDI52) {
          prefijo = "N52F";
        } else if (user.areas == AREAS_ZOE365) {
          prefijo = "ZOF";
        } else if (user.areas == AREAS_NOYDI8) {
          prefijo = "N8F";
        } else if (user.areas == AREAS_PALOMARES409) {
          prefijo = "P409F";
        } else if (user.areas == AREAS_BICENTENARIO89) {
          prefijo = "B89F";
        } else if (user.areas == AREAS_BICENTENARIO24) {
          prefijo = "B24F";
        } else if (user.areas == AREAS_BICENTENARIO52) {
          prefijo = "B52F";
        } else if (user.areas == AREAS_PARIAN59) {
          prefijo = "P59F";
        }
      } else {
        if (user.areas == AREAS_NOYDI52) {
          prefijo = "N52";
        } else if (user.areas == AREAS_ZOE365) {
          prefijo = "ZO";
        } else if (user.areas == AREAS_NOYDI8) {
          prefijo = "N8";
        } else if (user.areas == AREAS_PALOMARES409) {
          prefijo = "P409";
        } else if (user.areas == AREAS_BICENTENARIO89) {
          prefijo = "B89";
        } else if (user.areas == AREAS_BICENTENARIO24) {
          prefijo = "B24";
        } else if (user.areas == AREAS_BICENTENARIO52) {
          prefijo = "B52";
        } else if (user.areas == AREAS_PARIAN59) {
          prefijo = "P59";
        }
      }

   
        if (!vistaCliente) {
          toggleProgreso();
          let totalPedido = inputFields.length;

          try {
            await axios
              .post(
                `${URL_VENTAS_TIENDAS}Apartado`,
                {
                  fecha,
                  clientes: value._id,
                  colaboradores: selectedColaborador,
                  total_general: total_general.toFixed(2),
                  observaciones,
                  total_cantidad,
                  paqueterias: idPaqueteria,
                  areas: user.areas,
                  comisionTDC,
                  comisionFlete,
                  subTotal: subTotal.toFixed(2),
                  iva: iva.toFixed(2),
                  impuestos: selectedImpuesto,
                  cond_pago: condPago,
                  fiscal,
                  saldo: saldo.toFixed(2),
                  cortesia: 0,
                  prefijo,
                  consecutivo: 0,
                  entregado: "No",
                  descuento,
                  areaSurtido: selectedArea,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((data) => {
                

                inputFields.map((a) => {
                  if (a.articulos != "" && a.cantidad != 0) {
                    axios
                      .post(
                        `${URL_ARTICULOS_VENTAS_TIENDAS}Apartado`,
                        {
                          fecha,
                          ventasTiendas: data.data._id,
                          articulos: a.articulos,
                          cantidad: parseFloat(a.cantidad),
                          precio: parseFloat(a.precio),
                          total: parseFloat(a.total),
                          inventarios: a.idInv,
                          entregado: "No",
                          costoUnitario: a.costoUnitario,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        totalPedido = totalPedido - 1;
                        if (totalPedido == 0) {
                          axios
                            .post(
                              URL_LOGS,
                              {
                                tipo: "Crear Apartado de Tienda",
                                detalle: `${value._id} ${total_general}`,
                                user: user.id,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              Swal.fire(
                                "Good job!",
                                "Creado con exito",
                                `success`
                              );
                              setModalProgreso(false);
                              // clear();
                              setTimeout(() => {
                                window.location.reload();
                              }, 1000);
                            });
                        }
                      });
                  } else {
                    totalPedido = totalPedido - 1;
                    if (totalPedido == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Apartado de Tienda",
                            detalle: `${value._id} ${total_general}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", `success`);
                          setModalProgreso(false);
                          // clear();
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        });
                    }
                  }
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response}`,
            });
            console.log(error);
          }
        } else {
          if (
            selectedGrupo != "" &&
            selectedDepartamento != "" &&
            selectedPaqueteria != "" &&
            selectedAreaCliente != "" &&
            selectedVendedor != ""
          ) {
            toggleProgreso();

            try {
              await axios
                .post(
                  URL_CLIENTES,
                  {
                    nombre_comercial,
                    razon_social,
                    RFC,
                    calle,
                    numero_ext,
                    numero_int,
                    delegacion,
                    estado,
                    pais,
                    cp,
                    colonia,
                    ciudad,
                    telefono,
                    email,
                    grupos: selectedGrupo,
                    departamentosClientes: selectedDepartamento,
                    vendedor: selectedVendedor,
                    observaciones: observacionesCliente,
                    ultimo_pago: "2000-01-01",
                    forma_pago,
                    // codigo,
                    areas: selectedAreaCliente,
                    plazo,
                    regFiscal,
                    cargos: 0,
                    abonos: 0,
                    saldo: 0,
                    descuento: descuentoCliente,
                    alertas:"Si"
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(async(dataCliente) => {
                      //  Crear Venta

                      let totalPedido = inputFields.length;

                      try {
                        await axios
                          .post(
                            `${URL_VENTAS_TIENDAS}Apartado`,
                            {
                              fecha,
                              clientes: dataCliente.data._id,
                              colaboradores: selectedVendedor,
                              total_general: total_general.toFixed(2),
                              observaciones,
                              total_cantidad,
                              paqueterias: selectedPaqueteria,
                              areas: user.areas,
                              comisionTDC,
                              comisionFlete,
                              subTotal: subTotal.toFixed(2),
                              iva: iva.toFixed(2),
                              impuestos: selectedImpuesto,
                              cond_pago: condPago,
                              fiscal,
                              saldo: saldo.toFixed(2),
                              cortesia: 0,
                              prefijo,
                              consecutivo: 0,
                              entregado: "No",
                              descuento,
                              areaSurtido: selectedArea,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then((data) => {
                            PDFOC(data.data._id, data.data.idVentaTienda, prefijo);

                            inputFields.map((a) => {
                              if (a.articulos != "" && a.cantidad != 0) {
                                axios
                                  .post(
                                    `${URL_ARTICULOS_VENTAS_TIENDAS}Apartado`,
                                    {
                                      fecha,
                                      ventasTiendas: data.data._id,
                                      articulos: a.articulos,
                                      cantidad: parseFloat(a.cantidad),
                                      precio: parseFloat(a.precio),
                                      total: parseFloat(a.total),
                                      inventarios: a.idInv,
                                      entregado: "No",
                                      costoUnitario: a.costoUnitario,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer: ${localStorage.getItem(
                                          "app_token"
                                        )}`,
                                      },
                                    }
                                  )
                                  .then(() => {
                                    totalPedido = totalPedido - 1;
                                    if (totalPedido == 0) {
                                      axios
                                        .post(
                                          URL_LOGS,
                                          {
                                            tipo: "Crear Apartado de Tienda",
                                            detalle: `${value._id} ${total_general}`,
                                            user: user.id,
                                          },
                                          {
                                            headers: {
                                              Authorization: `Bearer: ${localStorage.getItem(
                                                "app_token"
                                              )}`,
                                            },
                                          }
                                        )
                                        .then(() => {
                                          Swal.fire(
                                            "Good job!",
                                            "Creado con exito",
                                            `success`
                                          );
                                          setModalProgreso(false);
                                          // clear();
                                          setTimeout(() => {
                                            window.location.reload();
                                          }, 1000);
                                        });
                                    }
                                  });
                              } else {
                                totalPedido = totalPedido - 1;
                                if (totalPedido == 0) {
                                  axios
                                    .post(
                                      URL_LOGS,
                                      {
                                        tipo: "Crear Apartado de Tienda",
                                        detalle: `${value._id} ${total_general}`,
                                        user: user.id,
                                      },
                                      {
                                        headers: {
                                          Authorization: `Bearer: ${localStorage.getItem(
                                            "app_token"
                                          )}`,
                                        },
                                      }
                                    )
                                    .then(() => {
                                      Swal.fire(
                                        "Good job!",
                                        "Creado con exito",
                                        `success`
                                      );
                                      setModalProgreso(false);
                                      // clear();
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 1000);
                                    });
                                }
                              }
                            });
                          })
                          .catch((error) => {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                              footer: `${error.response}`,
                            });
                            console.log(error);
                            setValidaBoton(true);
                          });
                      } catch (error) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response}`,
                        });
                        console.log(error);
                      }

                      // Fin Crear Venta
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data.message}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data.message}`,
              });
              console.log(error);
              setValidaBoton(true);
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Por favor revisa el formulario, faltan Datos",
            });
          }
        }
      
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No puedes vender $50 menos del precio original",
        showConfirmButton: false,
      });
    }
  };

  async function PDFOC(idPDFOC, consecutivo, prefijo) {
    let cajas = inputFields.map((c) => parseFloat(c.cajas));
    let TC = cajas.reduce((t, total, index) => t + total, 0);

    let LA = inputFields.length * 1.5;
    let largoTotal = LA + 4 + 10;

    const data = inputFields
      .sort((a, b) => (a.articulosNombre > b.articulosNombre ? 1 : -1))
      .map((a) => {
        return [
          a.articulosNombre,
          new Intl.NumberFormat("en-US").format(a.cantidad),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.precio),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total),
        ];
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("p", "cm", [8, largoTotal], true);
    var base64 = qrcode.toDataURL(idPDFOC);
    var img3 = new Image();
    img3.src = base64;

    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";

    doc.addImage(img2, "png", 5, 1, 2, 2);

    doc.setFontSize(6).setFont(undefined, "bold");
    doc.text(`NOYDI`, 0.3, 1);
    doc.text(`Sucursal:`, 0.3, 1.3);
    doc.text(`Direccion:`, 0.3, 1.6);
    doc.text(`Colonia:`, 0.3, 1.9);
    doc.text(`Delegacion:, CP:`, 0.3, 2.2);
    doc.text(`Atencian al Cliente:`, 0.3, 4);

    doc.text(`Fecha: ${fecha}`, 0.3, 2.5);
    doc.text(
      `Apartado: ${prefijo}${consecutivo} / Almacen: ${selectedAreaName}`,
      0.3,
      2.8
    );
    doc.text(
      `Piezas: ${new Intl.NumberFormat("en-US").format(
        total_cantidad
      )} / Cajas ${TC.toFixed(2)}`,
      0.3,
      3.1
    );

    if (!vistaCliente) {
      doc.text(`Cliente: ${value.nombre_comercial}`, 0.3, 3.4);
    } else {
      doc.text(`Cliente: ${nombre_comercial}`, 0.3, 3.4);
    }

    doc.text(`Vendedor: ${selectedColaboradorName}`, 0.3, 3.7);
    doc.setFontSize(7).setFont(undefined, "bold");
    doc.text(`APARTADO`, 3, 4.4);

    doc.autoTable({
      head: [["Articulo", "Cant", "Precio", "Total"]],
      body: dataPDFLimpia,
      styles: {
        fontSize: 8,
      },
      startY: 4.6,
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      foot: [
        [
          "",
          { content: "SubTotal", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(subTotal),
        ],
        [
          "",
          {
            content: `Descuento ${descuento}%`,
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(descuentoNumero),
        ],
        [
          "",
          { content: `Comision`, colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(iva),
        ],
        [
          "",
          { content: "Comision TDC", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(comisionTDC),
        ],
        [
          "",
          { content: "Flete", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(comisionFlete),
        ],
        [
          "",
          { content: "Total", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(total_general),
        ],
      ],
      showFoot: "lastPage",
    });


    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
    // doc.save(`Nota-${prefijo}${idVentaTienda}.pdf`);
  }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInv: "",
        cantInv: 0,
        articulos: "",
        articulosNombre: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        linea: "",
        familia: "",
        marca: "",
        precioEsp: "Si",
        venta: 0,
        costoUnitario: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let totalKG = values.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      // parseFloat(cortesia);

      setTotalGeneral(totTemp);
    setSaldo(
      totTemp - efectivo - tdc - transfer
      //  - cortesia
    );
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        if (i.cantInv >= cantTemp || event.target.value == "") {
          i.cajas = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (i.cantInv >= event.target.value) {
          i.cantidad = event.target.value;
          i.cajas =
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.venta;
          let idInv = a._id;
          let cantInv = a.cantidad;
          let articulosNombre = a.codigo;
          let linea = a.linea;
          let familia = a.familia;
          let marca = a.marca;
          let precioEsp = a.precioEsp;
          let venta = a.venta;
          let costoUnitario = a.costoUnitario;
          let precioOriginal = a.venta;
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            idInv,
            cantInv,
            articulosNombre,
            linea,
            familia,
            marca,
            precioEsp,
            venta,
            costoUnitario,
            precioOriginal
          );
        }
      });
    }
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    idInv,
    cantInv,
    articulosNombre,
    linea,
    familia,
    marca,
    precioEsp,
    venta,
    costoUnitario,
    precioOriginal
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        i.idInv = idInv;
        i.cantInv = cantInv;
        i.articulosNombre = articulosNombre;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.linea = linea;
        i.familia = familia;
        i.marca = marca;
        i.precioEsp = precioEsp;
        i.venta = venta;
        i.costoUnitario = costoUnitario;
        i.precioOriginal = precioOriginal;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }

    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      // parseFloat(cortesia) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
    setSaldo(
      totTemp - efectivo - tdc - transfer
      // - cortesia
    );
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(selectedCliente) {
    if (selectedCliente) {
      setValue(selectedCliente);

      axios
        .get(`${URL_CLIENTES}/${selectedCliente._id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allCliente = res.data;

          setCliente(allCliente);
          setSelectedColaborador(allCliente.vendedor[0]._id);
          setSelectedColaboradorName(
            allCliente.vendedor[0].nombre +
              " " +
              allCliente.vendedor[0].apellido
          );
        })
        .catch((err) => {
          console.log(err);
        });

     
    }
  }



  function calculaImpuesto(e) {
    setSelectedImpuesto(parseFloat(e));
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (e * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      // parseFloat(cortesia);
      setTotalGeneral(totTemp);
    setSaldo(
      totTemp - efectivo - tdc - transfer
      // - cortesia
    );
    // if (e > 0.01 || tdc > 0.01 || transfer > 0.01) {
    //   setFiscal("Si");
    // } else {
    //   setFiscal("No");
    // }
  }

  function calculaDescuento(e) {
    setDescuento(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    let temp = subTotal - temp1;

    let totTemp = temp + parseFloat(comisionTDC) + parseFloat(comisionFlete);

    // setSubTotal(temp)
    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  function jalaComisionTDC(e) {
    setComisionTDC(parseFloat(e));
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(e) +
      parseFloat(comisionFlete);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  function jalaComisionFlete(e) {
    setComisionFlete(parseFloat(e));
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(e);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  function autorizaCambio() {
    if (password == randomCambiaPrecio) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
      Swal.fire("Good job!", "Se autorizo con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

 

  function solicitarAut() {
    setMailAutEnviado(true);

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: process.env.REACT_APP_EMAIL_SUPER_ADMIN,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>La tienda ${selectedAreaName} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.ventasTiendas ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Apartado</h3>
          <Form onSubmit={saveVenta}>
            <Input
              type="checkbox"
              onChange={(event) => setVistaCliente(event.currentTarget.checked)}
              checked={vistaCliente}
            />
            <h4 className="subMenuTitulo">Deseas Dar de alta al Cliente?</h4>

            {vistaCliente ? (
              <>
                <Row>
                  <Col md={3}>
                    <Label>Nombre Comercial</Label>
                    <Input
                      type="text"
                      placeholder="Nombre Comercial"
                      value={nombre_comercial}
                      required
                      onChange={(e) => {
                        setNombreComercial(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Razon Social</Label>
                    <Input
                      type="text"
                      placeholder="Razon Social"
                      value={razon_social}
                      required
                      onChange={(e) => {
                        setRazonSocial(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>RFC</Label>
                    <Input
                      type="text"
                      placeholder="RFC"
                      value={RFC}
                      required
                      onChange={(e) => {
                        setRFC(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Paqueteria</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedPaqueteria}
                      required
                      onChange={(e) => {
                        setSelectedPaqueteria(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona </option>
                      {paqueterias
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Label>Calle</Label>
                    <Input
                      type="text"
                      placeholder="Calle"
                      value={calle}
                      required
                      onChange={(e) => {
                        setCalle(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>No. Ext</Label>
                    <Input
                      type="text"
                      placeholder="No. Ext"
                      value={numero_ext}
                      required
                      onChange={(e) => {
                        setNumeroExt(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>No. Int</Label>
                    <Input
                      type="text"
                      placeholder="No. Int"
                      value={numero_int}
                      required
                      onChange={(e) => {
                        setNumeroInt(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Pais</Label>
                    <Input
                      type="text"
                      placeholder="Pais"
                      value={pais}
                      required
                      onChange={(e) => {
                        setPais(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Label>Estado</Label>
                    <Input
                      type="text"
                      placeholder="Estado"
                      value={estado}
                      required
                      onChange={(e) => {
                        setEstado(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Ciudad</Label>
                    <Input
                      type="text"
                      placeholder="Ciudad"
                      value={ciudad}
                      required
                      onChange={(e) => {
                        setCiudad(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Delegacion</Label>
                    <Input
                      type="text"
                      placeholder="Delegacion o Municipio"
                      value={delegacion}
                      required
                      onChange={(e) => {
                        setDelegacion(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Colonia</Label>
                    <Input
                      type="text"
                      placeholder="Colonia"
                      value={colonia}
                      required
                      onChange={(e) => {
                        setColonia(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Label>CP</Label>
                    <Input
                      type="text"
                      placeholder="CP"
                      value={cp}
                      required
                      onChange={(e) => {
                        setCP(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Telefono</Label>
                    <Input
                      type="text"
                      placeholder="Telefono"
                      value={telefono}
                      required
                      onChange={(e) => {
                        setTelefono(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Vendedor</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedVendedorInput}
                      required
                      onChange={(e) => {
                        setSelectedVendedorInput(e.target.value);
                        setSelectedVendedor(e.target.value.split(",")[0]);
                        setSelectedColaboradorName(
                          e.target.value.split(",")[1]
                        );
                      }}
                    >
                      <option value="0">Selecciona un Vendedor</option>
                      {colaboradores
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option
                              value={[a._id, a.nombre + " " + a.apellido]}
                            >
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <Label>Forma de Pago</Label>
                    <Input
                      type="select"
                      placeholder="Forma de Pago"
                      value={forma_pago}
                      required
                      onChange={(e) => {
                        setFormaPago(e.target.value);
                      }}
                    >
                      <option value="0">Forma de Pago</option>
                      <option value="Contado">Contado</option>
                      <option value="Credito">Credito</option>
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label>Plazo</Label>
                    <Input
                      type="select"
                      placeholder="Plazo"
                      value={plazo}
                      required
                      onChange={(e) => {
                        setPlazo(e.target.value);
                      }}
                    >
                      <option value="">Plazo</option>
                      <option value="0">0</option>
                      <option value="30">30</option>
                      <option value="60">60</option>
                      <option value="90">90</option>
                      <option value="120">120</option>
                      <option value="150">150</option>
                      <option value="180">180</option>
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label>Reg Fiscal</Label>
                    <Input
                      type="select"
                      placeholder="RegFiscal"
                      value={regFiscal}
                      required
                      onChange={(e) => {
                        setRegFiscal(e.target.value);
                      }}
                    >
                      <option value="">Reg Fiscal</option>
                      <option value="0">0</option>
                      <option value="601">
                        601 General de Ley Personas Morales
                      </option>
                      <option value="603">
                        603 Personas Morales con Fines no Lucrativos
                      </option>
                      <option value="605">
                        605 Sueldos y Salarios e Ingresos Asimilados al Salario
                      </option>
                      <option value="608">608 Demas Ingresos</option>
                      <option value="612">
                        612 Personas Fisicas con Actividad Empresarial
                      </option>
                      <option value="616">616 Sin Obligacion Fiscal</option>
                      <option value="621">621 Incorporacion Fiscal</option>
                      <option value="625">
                        625 Reg. de las act. emp. cpn ingresos a traves de plat.
                        tec.
                      </option>
                      <option value="626">
                        626 Regimen simplificado de Confianza
                      </option>
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Grupo</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedGrupo}
                      required
                      onChange={(e) => {
                        setSelectedGrupo(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona una Grupo</option>
                      {grupos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Label className="mr-sm-2">Departamento</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedDepartamento}
                      required
                      onChange={(e) => {
                        setSelectedDepartamento(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona </option>
                      {departamentos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label className="mr-sm-2">Almacen</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedAreaCliente}
                      required
                      onChange={(e) => {
                        setSelectedAreaCliente(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          if (a._id != AREAS_GENERAL) {
                            if (user.areas == AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            } else if (user.areas == AREAS_BODEGA_SUR) {
                              if (
                                a._id == AREAS_BODEGA_SUR ||
                                a._id == AREAS_BODEGA_CENTRO
                              ) {
                                return <option value={a._id}>{a.name}</option>;
                              }
                            } else if (user.areas == a._id) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          }
                        })}
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observacionesCliente}
                      required
                      onChange={(e) => {
                        setObservacionesCliente(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Descuento</Label>
                    <Input
                      type="number"
                      placeholder="Descuento"
                      value={descuentoCliente}
                      required
                      onChange={(e) => {
                        setDescuentoCliente(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
              </>
            ) : undefined}
            <Row>
              <>
                {!vistaCliente ? (
                  <>
                    <Col md={3}>
                      <Label className="mr-sm-2">Cliente</Label>
                      <Autocomplete
                        size="small"
                        value={value}
                        onChange={(event, selectedCliente) => {
                          jalaCliente(selectedCliente);
                        }}
                        options={options.sort(
                          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                        )}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.junta}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecciona"
                            variant="outlined"
                          />
                        )}
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.codigo} {option.nombre_comercial}
                          </React.Fragment>
                        )}
                      />
                    </Col>


                    <Col md={1}>
                      <Label>Paqueteria</Label>
                      <Input
                        className="col-sm-12"
                        type="select"
                        value={idPaqueteria}
                        required
                        onChange={(e) => {
                          setIdPaqueteria(e.target.value);
                        }}
                      >
                        <option value="0">Selecciona </option>
                        {paqueterias
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>
                  </>
                ) : undefined}
                {vistaCliente ? (
                  <>
                    <Col md={12}>
                      <h4>Venta</h4>
                    </Col>
                    <br />
                  </>
                ) : undefined}

              </>
            </Row>
            <Row>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>

              <Col md={2}>
                <Label className="mr-sm-2">Almacen de Entrega</Label>
                <Input
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if ( a._id != AREAS_GENERAL &&
                        (a._id == AREAS_BODEGA_CENTRO || a._id == user.areas )) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>

              <Col md={1}>
                <Label>Cambio de Precio</Label>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={(e) => toggleCambiaPrecio()}
                >
                  Solicitar
                </Button>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              {/* <Col md={2}>
                <Label className="mr-sm-2">Codigo QR</Label>
              </Col> */}
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Inventario</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Piezas Por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio Original</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row
                // style={
                //   inputField.precioOriginal -31 > inputField.precio
                //     ? { backgroundColor: "#ed8c8c" }
                //     : null
                // }
                >
                  {/* <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </Col> */}
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosUnicos
                        .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a.idArticulo}>{a.codigo}</option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantInv"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cantInv}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  {/* {user.cambiaPrecios ||
                  inputField.precioEsp == "Si" ||
                  cambiarPrecio ? ( */}
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precioOriginal"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="PrecioOriginal"
                      value={inputField.precioOriginal}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      style={
                        inputField.precioOriginal - 50 > inputField.precio
                          ? { backgroundColor: "#ed8c8c" }
                          : null
                      }
                      bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  {/* ) : (
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="precio"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        disabled
                      />
                    </Col>
                  )} */}

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            {/* Termina Tabla Articulos */}

            <br />
            <Row>
              <Col md={4}></Col>
              <Col md={1}>
                <h4 align="right">Total Piezas:</h4>
              </Col>
              <Col md={1}>
                <h4 align="right">
                  {new Intl.NumberFormat("en-US").format(total_cantidad)}
                </h4>
              </Col>
              <Col md={1}></Col>
              <Col md={1}>
                <h4 align="right">Subtotal:</h4>
              </Col>
              <Col md={1}>
                <h4 align="right">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(subTotal)}
                </h4>
              </Col>
            </Row>

            <Row>
              <Col md={7}></Col>
              <Col md={1}>
                <h4 align="right">IVA:</h4>
              </Col>
              <Col md={1}>
                <h4 align="right">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(iva)}
                </h4>
              </Col>
            </Row>

            <Row>
              <Col md={7}></Col>
              <Col md={1}>
                <h4 align="right">Total:</h4>
              </Col>
              <Col md={1}>
                <h4 align="right">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(total_general)}
                </h4>
              </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/MenuTiendas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado == false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ApartadosTiendasCreate;
