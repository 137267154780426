import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header";
import { AuthContext } from "../../contexts/AuthContext";

function HomeInicio() {
  // const { isAuth } = useContext(AuthContext);

  const history = useHistory();

  // function handleClick() {
  //   history.push("/login");
  // }
  return (
    <>
      <Header />
      <div className="imgContainer">
        <img
          src={process.env.PUBLIC_URL + "/LogoInstitucion.png"}
          alt="auto"
          className="imgLogo"
        />
      </div>
    </>
  );
}

export default HomeInicio;
